import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, catchError, timeout } from 'rxjs/operators';
import * as constant from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class HttpsInterceptor implements HttpInterceptor {
  constructor(private route: Router) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes('lmslogin')) {
      if (!req.url.includes('chat')) {
        // this.mdbSpinningPreloader.start();
      }

      const userToken = localStorage.getItem(constant.environment.authTokenKey);
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${userToken}`,
          'x-access-token': `${userToken}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': '*',
          'X-Requested-With': 'XMLHttpRequest',
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      });
    } else {
      req = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': '*',
          'X-Requested-With': 'XMLHttpRequest',
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      });
    }
    return next.handle(req)
      .pipe(
        timeout(100000),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error\
            if (error.status === 0) {
              errorMessage = 'Please check your internet connection';
            } else if (error.status === 403) {
              errorMessage = 'Login Expired';
              localStorage.removeItem('authtoken');
              this.route.navigate(['/login']);
            } else {
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
          }
          //  this.toastrService.error('Please check your internet connection,\n Please refresh');
          return throwError(errorMessage);
        }),
        finalize(() => {
          // this.mdbSpinningPreloader.stop();
          // this.ts.warning('Please check your internet connection');
        }),
      );
  }

}
export const httpInterceptorProviders = [{
  provide: HTTP_INTERCEPTORS,
  useClass: HttpsInterceptor,
  multi: true
},

];
