import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WeeklyReportService {
  apiUrl: string;

  constructor(private http: HttpClient) { 
    this.apiUrl = environment.apiUrl;
  }


  getAllBadges(): Observable<any> {
    return this.http.get(`${this.apiUrl}v1/courseId`);
  }


  getDetails(payload: any): Observable<any> {
    return this.http.post(`${this.apiUrl}v1/weeklyReport`, payload, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }
  
  
}
