// import { DatePipe } from '@angular/common'; // Uncomment if used
import { Component, OnInit } from '@angular/core';
import { WeeklyReportService } from '../alumni-scores/services/weekly-report.service'; // Adjust the import if necessary
import { ColumnMode } from '@swimlane/ngx-datatable';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-all-report',
  templateUrl: './all-report.component.html',
  styleUrls: ['./all-report.component.scss']
})
export class AllReportComponent implements OnInit { 

  ColumnMode = ColumnMode;
  search: string = '';
  bookOrdersReports: any[] = [];
  pagination = {
    totalElements: 0, 
    pageNumber: 0,
    size: 20
  }
  
  selectedBatch: number | null = null;  
  examType: string = ''; 
  reportSummary: boolean = true;
  allDetails: any[] = []; 
  modules: string[] = []; 
  reportData: any = {};
  batches: any[] = []; 
  currentPage: number = 0;
  pageSize: number = 10;
  pagedData: any[] = [];

  constructor(private weeklyReport: WeeklyReportService) { }

  ngOnInit(): void {
    this.weeklyReport.getAllBadges().subscribe((res: any) => {
      this.batches = res.data || []; 
    });
  }

  getDetails() {
    const payload: any = {
      type: this.examType,
      batchId: this.selectedBatch ? [this.selectedBatch] : [], 
    };
    if (this.search.trim()) {
      payload.search = this.search;
    }

    this.weeklyReport.getDetails(payload).subscribe(
      (res: any) => {
        if (res && res.data) {
          this.allDetails = res.data;
          this.reportData = res;

          if (res.metaData?.modules) {
            this.modules = res.metaData.modules;
          } else if (res.data.length > 0 && res.data[0].module) {
            this.modules = res.data[0].module.map((m: any) => m.title);
          }
          this.updatePagedData(); 
        } else {
          this.allDetails = [];
        }
      },
      (error) => {
        console.error('Error fetching details:', error);
      }
    );
  }

  onSearchChange(searchValue: string) {
    this.search = searchValue;
    this.getDetails();
  }

  updatePagedData() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.pagedData = this.allDetails.slice(startIndex, endIndex);
    console.log(this.pagedData, "Paged Data");
  }

  nextPage() {
    const totalPages = Math.ceil(this.allDetails.length / this.pageSize);
    if (this.currentPage < totalPages - 1) {
      this.currentPage++;
      this.updatePagedData();
    }
  }
  

  prevPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.updatePagedData();
    }
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.updatePagedData();
  }

  exportCsv() {
    if (!this.reportData?.metaData?.modules || !Array.isArray(this.reportData.metaData.modules)) {
      console.error("No report data available to export.");
      return;
    }

    const moduleHeaders = this.reportData.metaData.modules.flatMap((module: string) => [
      `${module} Completion`, 
      `${module} Accuracy`
    ]); 

    const headers = ['Can ID', 'Candidate Name', ...moduleHeaders, 'Overall'];
    const rows = this.allDetails.map((detail: any) => {
      const moduleData = detail.module.map((mod: any) => [
        mod.completion || 0, 
        mod.accuracy || 0
      ]).flat();
      return [
        detail.canId,
        detail.name,
        ...moduleData, 
        detail.overall || 0 
      ];
    });

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\n'; 
    rows.forEach(row => {
      csvContent += row.join(',') + '\n';  
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'weekly_report.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
