import { Injectable } from '@angular/core';
import * as constant from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';
import { ExportToCsv } from 'export-to-csv';


@Injectable({
  providedIn: 'root'
})
export class UpdatesNotificationService {
  apiUrl = constant.environment.apiUrl;

  constructor(private http: HttpClient) { }

  getCategoryDropdown(params) {
    return this.http.post(this.apiUrl + "feedback/dropdownCategoryList", params);
  }
  getCategoryList(data) {
    return this.http.post(this.apiUrl + "feedback/categoryList", data);
  }
  getCategoryDetails(data) {
    return this.http.post(this.apiUrl + "feedback/getCategoryDetails", data);
  }
  updateCategories(data) {
    return this.http.post(this.apiUrl + "feedback/updateCategories", data);
  }

  getTrainerDropdown(params) {
    return this.http.post(this.apiUrl + "feedback/dropdownTrainerList", params);
  }
  getTrainerList(data) {
    return this.http.post(this.apiUrl + "feedback/TrainerList", data);
  }
  getTrainerDetails(data) {
    return this.http.post(this.apiUrl + "feedback/getTrainerDetails", data);
  }
  updateTrainer(data) {
    return this.http.post(this.apiUrl + "feedback/updateTrainer", data);
  }
  getServiceTeamList(data) {
    return this.http.post(this.apiUrl + "feedback/serviceTeamList", data);
  }
  updateServiceTeam(data) {
    return this.http.post(this.apiUrl + "feedback/updateServiceTeam", data);
  }
  getServiceTeamDetails(data) {
    return this.http.post(this.apiUrl + "feedback/getServiceTeamDetails", data);
  }
  getServiceTeamDropdown(data) {
    return this.http.post(this.apiUrl + "feedback/dropdownServiceTeamList", data);
  }
  updateCategoryQuestion(data) {
    return this.http.post(this.apiUrl + "feedback/updateCategoryQuestion", data);
  }
  getCategoryQuestionList(data) {
    return this.http.post(this.apiUrl + "feedback/getCategoryQuestionList", data);
  }
  updateCategoryQuestionStatus(data) {
    return this.http.post(this.apiUrl + "feedback/updateCategoryQuestionStatus", data);
  }
  getFeedbackReport(data) {
    return this.http.post(this.apiUrl + "feedback/getFeedbackReport", data);
  }

  getFeedbackCategoryQuestionsDetails(data) {
    return this.http.post(this.apiUrl + "feedback/getFeedbackCategoryQuestionsDetails", data);
  }

  getSubQuestionList(data) {
    return this.http.post(this.apiUrl + "feedback/getSubquestionDropdownList", data);
  }

  getMentorTrainersList(data) {
    return this.http.post(this.apiUrl + "feedback/getMentoringDropdownList", data);
  }

  getAdminHelpdeskList(data) {
    return this.http.post(this.apiUrl + "feedback/getAdminHelpdeskList", data);
  }

  getHelpdeskDetails(data) {
    return this.http.post(this.apiUrl + "helpdesk/getHelpdeskDetails", data);
  }

  moveTicket(data) {
    return this.http.post(this.apiUrl + "helpdesk/moveTicket", data);
  }

  dropdownHelpdeskUserList(data) {
    return this.http.post(this.apiUrl + "helpdesk/dropdownHelpdeskUserList", data);
  }

  getAdminCommentList(data) {
    return this.http.post(this.apiUrl + "helpdesk/getAdminCommentList", data);
  }

  updateAdminComment(data) {
    return this.http.post(this.apiUrl + "helpdesk/updateAdminComment", data);
  }

  deleteAdminComment(data) {
    return this.http.post(this.apiUrl + "helpdesk/deleteAdminComment", data);
  }

  dropdownServiceTeamList(data) {
    return this.http.post(this.apiUrl + "feedback/dropdownServiceTeamList", data);
  }

  getAdminViewHelpdeskHistoryList(data) {
    return this.http.post(this.apiUrl + "helpdesk/getAdminViewHelpdeskHistoryList", data);
  }

  getGPTeamDropdown(params) {
    return this.http.post(this.apiUrl + "helpdesk/getGPTeamDropdownList", params);
  }

  getExport(params: any) {
    this.http.post(this.apiUrl + 'helpdesk/getAdminHelpdeskListExport', params).subscribe((result: any) => {
      console.log(result)
      const formula: string = "Formula 1";
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        filename: new Date().toLocaleString() + '-helpdesk-report',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };
      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(result.data);
    });
  }

  getUniversityDropdownList(params) {
    return this.http.post(this.apiUrl + "universityProgramFeedback/getUniversityDropdownList", params);
  }

  getCourseByUniversityDropdownList(params) {
    return this.http.post(this.apiUrl + "getCoursesByUniversity", params);
  }

  getParentBatchDropdownList(params) {
    return this.http.post(this.apiUrl + "get-all-parent-batch", params);
  }

  getCourseDropdownList(params) {
    return this.http.post(this.apiUrl + "universityProgramFeedback/getUniversityCourseDropdownList", params);
  }

  getFacultyDropdownList(params) {
    return this.http.post(this.apiUrl + "universityProgramFeedback/getUniversityFacultyDropdownList", params);
  }

  getBatchDropdownList(params) {
    return this.http.post(this.apiUrl + "get-all-batch-by-university", params);
  }

  getSessionDropdownList(params) {
    return this.http.post(this.apiUrl + "universityProgramFeedback/getUniversitySessionDropdownList", params);
  }

  getUniversityFeedbackList(params) {
    return this.http.post(this.apiUrl + "universityProgramFeedback/getAdminUniverstyFeedbackList", params);
  }

  getUniversityCourseFeedbackList(params) {
    return this.http.post(this.apiUrl + "universityProgramFeedback/getAdminUniverstyFeedbackList", params);
  }

  changeNotificationStatus(payload) {
    return this.http.post(this.apiUrl + "changeNotificationStatus", payload);
  }




  updateStudyLoungeDates(formValue: any){
    return this.http.post(this.apiUrl + "admin/update-update", formValue);
  }

  createUpdate(formValue: any){
    return this.http.post(this.apiUrl + "admin/create-update", formValue);
  }

  getStudyLoungeDates(payload:any){
    return this.http.post(this.apiUrl + "admin/get-updates", payload);
  }

  uploadUpdateDocOne(params : any){
    return this.http.post(this.apiUrl + 'student/uploadFile', params);
  }
}
