import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-ngx-data-table',
  templateUrl: './ngx-data-table.component.html',
  styleUrls: ['./ngx-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxDataTableComponent {

  @Input() column: any[] = []; // Column definitions for ngx-datatable
  @Input() row: any[] = []; // Row data for the table
  @Input() actions: any[] = []; // List of dynamic actions to display

  
  @Output() actionTriggered = new EventEmitter<{action: string, row: any}>();
  headers:any = [];
  ColumnMode = ColumnMode; // Use ColumnMode from ngx-datatable for flexibility (e.g., flex or standard)

  onAction(action: string, row: any): void {
    this.actionTriggered.emit({ action, row });
  }

  ngOnChanges() {
    this.headers = [];
    // Ensures changes are detected after the view is initialized
    this.column.forEach((element: any) => {
      this.headers.push({ name: element.name, prop: element.prop, width:element.width, type:element.type });
    });
    if(this.actions.length > 0) {
      this.headers.push({ name: 'Action', prop: 'action', width:150, type:'' });
    }
  }

  checkCondition(name: string) {
    if (name === 'Action') {
      return true;
    }
    return false;
  }


  

}
