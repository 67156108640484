import { Injectable } from '@angular/core';
import * as constant from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';
import { MenuItem } from './menu.model';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  apiUrl = constant.environment.apiUrl;
  getMenuAccessObserver = new Subject();

  constructor(private http: HttpClient) { }
//   getMenu(){
//     var courseId: any = localStorage.getItem('course_id');
//     var roleId: any = localStorage.getItem('role_id');
//     var menu = [

//   {
//     label: 'Main',
//     isTitle: true,
//     visibility: 1
//   },
//   {
//     label: 'Dashboard',
//     icon: 'fad fa-home',
//     link: '/dashboard/calling/',
//     visibility: 1
//   },
//   {
//     label: 'SPOC Report',
//     icon: 'fad fa-user-chart',
//     link: '/spoc-report',
//     visibility: (courseId==1 && roleId==1)||(courseId==1 && roleId==4)?1:0
//   },
//   {
//     label: 'Forum Report',
//     icon: 'fad fa-chart-line',
//     link: '/forum-report',
//     visibility: (courseId==1 && roleId==1)?1:0
//   },

//   {
//     label: 'User',
//     icon: 'fad fa-user',
//     link: '/user',
//     visibility: roleId==1?1:0
//   },
//   {
//     label: 'Jobs',
//     icon: 'fad fa-clipboard',
//     link: '/jobs',
//     visibility: roleId==1?1:0
//   },
//   {
//     label: 'Time-Tracker',
//     icon: 'fad fa-user-clock',
//     link: '/time-tracker',
//     visibility : roleId==6 || roleId==8 || roleId==7 || roleId==1 || roleId==4?1:0
//   },
//   {
//     label: 'Time-Tracker Admin',
//     icon: 'fad fa-user-clock',
//     link: '/time-tracker/admin',
//     visibility: roleId==1?1:0
//   },
//   {
//     label: "Zoom",
//     icon: "fad fa-video",
//     link: "/zoom",
//     visibility: roleId==1?1:0
//   },
//   {
//     label: 'MAC',
//     isTitle: true,
//     visibility: roleId==1?1:0
//   },
//   {
//     label: 'Orders',
//     icon: 'fad fa-bags-shopping',
//     link: 'lms/orders',
//     visibility: roleId==1?1:0
//   },

//   {
//     label: 'Miles LMS',
//     isTitle: true,
//     visibility: courseId==1 && (roleId==1 || roleId==4)?1:0
//   },
//   {
//     label: 'Gameplan',
//     icon: 'fad fa-trophy-alt',
//     subItems: [
//       {
//         label: 'Inbox',
//         // icon: 'fad fa-inbox-in',
//         link: 'lms/gameplan/inbox/',
//       },
//       {
//         label: 'Outbox',
//         link: 'lms/gameplan/outbox/',
//       },
//       {
//         label: 'Transcript Creator',
//         link: 'lms/gameplan/creator/transcript/',
//       },
//       {
//         label: 'Evaluation Creator',
//         link: 'lms/gameplan/creator/evaluation/',
//       },
//     ],
//     visibility: courseId==1 && (roleId==1 || roleId==4)?1:0
//   },
//   {
//     label: 'CPA Mentoring',
//     icon: 'fad fa-users',
//     subItems: [
//       {
//         label: 'Appointments',
//         link: 'lms/mentoring/',
//       },
//       {
//         label: 'SPOC Allocation',
//         link: 'lms/mentoring/spoc/',
//       },
//       {
//         label: 'Slot Booking',
//         link: 'lms/mentoring/slot/',
//       },
//     ],
//     visibility: (roleId==1 || roleId==4)?1:0
//   },

//   {
//     label: 'CMA Mentoring',
//     icon: 'fad fa-users',
//     subItems: [
//       {
//         label: 'Appointments',
//         link: 'lms/mentoring-cma/',
//       },
//       {
//         label: 'SPOC Allocation',
//         link: 'lms/mentoring-cma/spoc/',
//       },
//       {
//         label: 'Slot Booking',
//         link: 'lms/mentoring-cma/slot/',
//       }
//     ],
//     visibility: roleId==1?1:0
//   },
  



// ];
//    return menu;
// // this.getMenuAccessObserver.next(menu);
//   }

  milesUserMenuAcesss(){
    this.http.post(this.apiUrl + 'admin/milesUserMenuAcesss', {}).subscribe((res: any) => {
      this.getMenuAccessObserver.next(res['data']);
    });
   

  }

}
