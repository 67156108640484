// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control, .dataTable-wrapper .dataTable-top .dataTable-search .dataTable-input, .ng-select .ng-select-container, select {
  color: #495057 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/views/pages/all-report/all-report.component.scss"],"names":[],"mappings":"AACA;EACI,yBAAA;AAAJ","sourcesContent":["\n.form-control, .dataTable-wrapper .dataTable-top .dataTable-search .dataTable-input, .ng-select .ng-select-container, select{\n    color: #495057 !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
