import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
// import { isNumber, toInteger, padNumber } from '@ng-bootstrap/ng-bootstrap/util/util';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
    month: any[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    parse(value: string): NgbDateStruct {
        if (value) {
          //  console.log('value', value);

            // const dateParts = value.trim().split('/');
            // if (dateParts.length === 1 && isNumber(dateParts[0])) {
            //     return { day: toInteger(dateParts[0]), month: null, year: null };
            // } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
            //     return { day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: null };
            // } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
            //     return { day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: toInteger(dateParts[2]) };
            // }
        }
        return null;
    }

    format(date: NgbDateStruct): string {
        return date ? date.day + '-' + this.month[date.month - 1] + '-' + date.year : '';
    }
}