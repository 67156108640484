import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { FormsModule } from '@angular/forms';


const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
      },

      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  {
    path: 'eligibility-report/:eligibilityCode',
    // component: GameplanComponent,
    // canDeactivate: [AuthGuard],

    loadChildren: () => import('./views/pages/lms/gameplan/student-eligibility-report/student-eligibility-report.module').then(s => s.StudentEligibilityReportModule)
  },
  {
    path: 'eligibility-report-us/:eligibilityCode',
    // component: GameplanComponent,
    // canDeactivate: [AuthGuard],

    loadChildren: () => import('./views/pages/lms/uspathway-gameplan/student-eligibility-report/student-eligibility-report.module').then(s => s.StudentEligibilityReportModule)
  },

  {
    path: "user-report",
    loadChildren: () => import("./views/pages/user-report/user-report.module").then((m) => m.UserReportModule),
    data: { title: "User Report Dashboard" },
  },
  {
    path: 'mac-post-report',
    // component: GameplanComponent,
    // canDeactivate: [AuthGuard],

    loadChildren: () => import('./views/pages/lms/mac-post-report/mac-post-report.module').then(s => s.MacPostReportModule)
  },
  // {
  //   path: 'university-report',
  //   // component: GameplanComponent,
  //   // canDeactivate: [AuthGuard],

  //   loadChildren: () => import('./views/pages/university-report/university-report.module').then(s => s.UniversityReportModule)
  // },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'Page Not Found',
      'desc': 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' },




];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
