// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar .navbar-content .navbar-nav .nav-item.dropdown .dropdown-menu .dropdown-header {
  border-bottom: 1px solid #cbced6;
}

.nav-user-email {
  background-color: rgba(64, 198, 198, 0.3098039216) !important;
  color: #000 !important;
  font-weight: 500;
  letter-spacing: 0.55px;
  border: 1px solid #116b6b !important;
  box-shadow: none;
  white-space: initial;
  overflow-wrap: anywhere;
}

.cust-profile-dropdown-menu {
  background-color: #dedede;
  border-color: #c7c3c3 !important;
  min-width: 275px !important;
}

.cust-profile-dropdown-menu::before {
  background: #dedede;
  border-top-color: #c7c3c3;
  border-left-color: #c7c3c3;
}`, "",{"version":3,"sources":["webpack://./src/app/views/layout/navbar/navbar.component.scss"],"names":[],"mappings":"AAAA;EAAyF,gCAAA;AAEzF;;AADA;EAAiB,6DAAA;EAAwC,sBAAA;EAAwB,gBAAA;EAAkB,sBAAA;EAAwB,oCAAA;EAAsC,gBAAA;EAAiB,oBAAA;EAAqB,uBAAA;AAYvM;;AAXA;EAA6B,yBAAA;EAA2B,gCAAA;EAAiC,2BAAA;AAiBzF;;AAhBA;EAAqC,mBAAA;EAAqB,yBAAA;EAA2B,0BAAA;AAsBrF","sourcesContent":[".navbar .navbar-content .navbar-nav .nav-item.dropdown .dropdown-menu .dropdown-header { border-bottom: 1px solid #cbced6; }\n.nav-user-email{ background-color: #40c6c64f !important; color: #000 !important; font-weight: 500; letter-spacing: 0.55px; border: 1px solid #116b6b !important; box-shadow: none;white-space: initial;overflow-wrap: anywhere; }\n.cust-profile-dropdown-menu{ background-color: #dedede; border-color:#c7c3c3 !important; min-width: 275px !important;}\n.cust-profile-dropdown-menu::before{ background: #dedede; border-top-color: #c7c3c3; border-left-color: #c7c3c3;}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
