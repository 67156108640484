// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #181d1f;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #727cf5;
}`, "",{"version":3,"sources":["webpack://./src/app/views/layout/sidebar/sidebar.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,WAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,mBAAA;AACJ","sourcesContent":["::-webkit-scrollbar-thumb {\n    width: 5px;\n    height: 5px;\n}\n\n::-webkit-scrollbar-track {\n    background: #181d1f;\n}\n\n::-webkit-scrollbar-thumb{\n    border-radius: 10px;\n    background: #727cf5;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
