import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UpdatesNotificationService } from 'src/app/core/services/updates-notification.service';

@Component({
  selector: 'app-upload-file-input',
  templateUrl: './upload-file-input.component.html',
  styleUrls: ['./upload-file-input.component.scss'],
})
export class UploadFileInputComponent {
  constructor(
    private updatesNotificationService: UpdatesNotificationService,
  ) {}
  @Input() buttonClass: string = 'btn mt-0 ml-0 mr-0'; // Allow custom styling
  @Input() isRequired: boolean = true; // Optional required attribute
  @Input() isMultiple: boolean = true; // Optional required attribute

  @Output() fileSelected = new EventEmitter<string[]>();

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    let filesUrls = [];
    let i = 0;
    while (i < input.files.length) {
      let formData = new FormData();
      formData.append('file', input.files[i]);
      formData.append('public_id', '');
      formData.append('bucket', 'miles-test-1');

      this.updatesNotificationService.uploadUpdateDocOne(formData).subscribe(
        (res: any) => {
          if (res) {
            const fileDetails = {
              original_filename : res.original_filename,
              secure_url : res.secure_url,
              format: res.format
            }
            filesUrls.push(fileDetails)
            if(input.files.length == filesUrls.length){
              this.fileSelected.emit(filesUrls);
            }
          }
        }
      );
      i++
    }
  }
}