// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new_page_wrapper {
  background-image: linear-gradient(#fafaff, #ececf9);
}`, "",{"version":3,"sources":["webpack://./src/app/views/layout/base/base.component.scss"],"names":[],"mappings":"AAAA;EAAmB,mDAAA;AAEnB","sourcesContent":[".new_page_wrapper{ background-image: linear-gradient(#fafaff, #ececf9);}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
