import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  adminDetailsObserver = this.authService.adminDetailsObserver.asObservable();
  userData : any = {};
  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private renderer: Renderer2,
    private router: Router,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.adminDetails();
    this.adminDetailsObserver.subscribe(data => {
      if (data) {
        this.userData = data;
      }
    });
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();
    localStorage.removeItem('authtoken');
    localStorage.removeItem('course_id');
    localStorage.removeItem('role_id');
    if (!localStorage.getItem('authtoken')) {
      this.router.navigate(['/auth/login']);
    }
  }

}
