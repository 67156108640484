import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor() {}
  
  /* Http Error handling based on the status code from the response */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) =>  {
        let errorMessage = "An unexpected error occurred. Please try again.";
        if (err) {
            if(err && err.toString().includes("400")) {
                errorMessage = "Oops! Something went wrong. It seems like your request is incorrect. Please double-check the data and try again.";
            }
            if(err && err.toString().includes("401")) {
                errorMessage = "You must be logged in to access this page. Please log in and try again.";
            }
            if(err && err.toString().includes("404")) {
                errorMessage = "Oops! The page you're looking for doesn't exist. Check the URL or return to the homepage.";
            }
            if(err && err.toString().includes("500")) {
                errorMessage = "Oops! Something went wrong on our side. We're working on it, please try again later.";
            }
        }
      return throwError(errorMessage);
  }))
  }
}