import { Injectable } from '@angular/core';
import * as constant from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';
import { MenuItem } from '../../views/layout/sidebar/menu.model';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl = constant.environment.apiUrl;
  menuListObserver = new Subject();
  menuAccessObserver = new BehaviorSubject({});
  adminDetailsObserver: BehaviorSubject<any> = new BehaviorSubject({});
  adminData = this.adminDetailsObserver.asObservable();
  getSingleUserDetailsObserver = new Subject();
  getUserListObserver = new Subject();
  getUserListMetaDataObserver = new Subject();
  getMenuAccessObserver = new Subject();
  constructor(private http: HttpClient) { }
  getAdminMenuList() {
    this.http.post(this.apiUrl + 'getAdminMenuList', {}).subscribe((res: any) => {
      if (res && res.status) {
        // tslint:disable-next-line: no-string-literal
        this.menuListObserver.next(res['data']);
      }
    });
  }
  login(params) {
    return this.http.post(this.apiUrl + "admin/lmslogin", params);
  }

  logoutUser(params) {
    return this.http.post(this.apiUrl + "admin/lmslogout", params);
  }

  forgotPassword(params) {
    return this.http.post(this.apiUrl + "admin/adminForgotPassword", params);
  }

  adminDetails() {
    this.http.post(this.apiUrl + 'admin/adminDetails', {}).subscribe((res: any) => {
      if (res && res.status) {
        this.adminDetailsObserver.next(res.data[0]);
      }
    });
  }
  getAdminAccessMenus() {
    this.http.post(this.apiUrl + 'getAdminAccessMenus', {}).subscribe((res: any) => {
      if (res && res.status) {
        // tslint:disable-next-line: no-string-literal
        this.menuAccessObserver.next(res['data']);
      }
    });
  }
  
  getRoleVisibility(param : any){
    var courseId: any = parseInt(localStorage.getItem("course_id"));
    var roleId: any = parseInt(localStorage.getItem("role_id"));
    
    const visibilityStatus = param.roleArray.includes(roleId) && courseId == param.courseId?true:false;
    const visibilityStatus2 = param.roleArray.includes(roleId)?true:false;

    return param.courseId?visibilityStatus:visibilityStatus2;
  }

}
